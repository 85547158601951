<template>
  <div class="proposal-item-content tw-flex tw-flex-col">
    <div class="proposal-item-content__addresses tw-w-full tw-flex">
      <ctk-address
        v-for="direction in ['pickup', 'delivery']"
        :key="direction"
        :address="proposal.offer[direction].address"
        :has-street-name="false"
        :data-test="`address-${direction}`"
        class="tw-flex-1 tw-w-1/2 tw-font-medium not-last:tw-mr-2"
      />
    </div>
    <div class="tw-flex tw-items-center tw-w-full tw-text-blue-500">
      <div
        class="tw-flex-1 tw-w-1/2 tw-mr-2"
        data-test="pickup-date"
      >
        <span
          v-text="$t('app.labels.pickup')"
          data-test="label"
        />
        <span
          class="tw-ml-1"
          v-text="pickupDate"
          data-test="value"
        />
      </div>
      <div
        class="tw-flex-1 tw-w-1/2"
        data-test="delivery-date"
      >
        <span
          v-text="$t('app.labels.delivery')"
          data-test="label"
        />
        <span
          class="tw-ml-1"
          v-text="deliveryDate"
          data-test="value"
        />
      </div>
    </div>

    <div class="tw-flex tw-items-center tw-mt-2 tw-w-full">
      <div
        class="tw-flex tw-items-center tw-flex-1"
        data-test="price"
      >
        <ui-ctk-icon
          name="budget"
          class="tw-text-blue-500 tw--ml-2"
          data-test="icon"
        />
        <span
          v-text="$t('app.labels.proposals.price')"
          class="tw-font-medium"
          data-test="label"
        />
        <span
          v-text="$t('price_excl_tax', {
            price: $options.filters.currency(price, proposal.offer.pricing.currency, $i18n.locale)
          })"
          class="tw-text-blue-500 tw-ml-1"
          data-test="value"
        />
      </div>
      <div
        class="tw-flex tw-items-center tw-flex-1"
        data-test="expiration"
      >
        <ui-ctk-icon
          name="expiration"
          class="tw-text-blue-500 tw--ml-2"
          data-test="icon"
        />
        <span
          v-text="$t('app.labels.expires_in')"
          class="tw-font-medium"
          data-test="label"
        />
        <span
          v-text="$moment(proposal.expires_at).fromNow(true)"
          class="tw-text-blue-500 tw-ml-1"
          data-test="value"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, defineComponent, toRefs } from '@vue/composition-api'

  import CtkAddress from '@/components/CtkAddress/index.vue'
  import ShipmentItemProposalsItemDatesPrice from '@/views/Carriers/Offers/components/ProposalsList/_subs/ProposalItem/_subs/ProposalItemContent/_subs/ShipmentItemProposalsItemDatesPrice/index.vue'
  import useMoment from '@/composables/useMoment'

  /**
   * @module component - ProposalItemContent
   * @param {Object} proposal
   */
  export default defineComponent({
    name: 'ProposalItemContent',
    components: {
      CtkAddress,
      ShipmentItemProposalsItemDatesPrice
    },
    props: {
      proposal: {
        type: Object,
        required: true
      }
    },
    setup (props) {
      const { proposal } = toRefs(props)
      const moment = useMoment()

      const price = computed(() => {
        return proposal.value.price || proposal.value.shipper_price || proposal.value.offer.pricing.carrier_price || proposal.value.offer.pricing.price
      })

      const pickupDate = computed(() => {
        return moment.value(proposal.value.pickup_date
          ? proposal.value.pickup_date
          : proposal.value.offer.pickup.time_slot.date).format('LL')
      })

      const deliveryDate = computed(() => {
        return moment.value(proposal.value.delivery_date
          ? proposal.value.delivery_date
          : proposal.value.offer.delivery.time_slot.date).format('LL')
      })

      return {
        price,
        pickupDate,
        deliveryDate
      }
    }
  })
</script>

<style lang="scss">

  .proposal-item-content {
    .shipment-item-proposals-item-dates-price {
      flex: 0.7;
      margin-left: 32px;
    }

    @media only screen and (max-width: 620px) {
      flex-direction: column;
      align-items: flex-start;

      .shipment-item-proposals-item-dates-price {
        margin-left: 16px;
      }
    }
  }

</style>
