<template>
  <div class="ctk-proposals-list tw-flex tw-flex-col tw-relative tw-flex-1 tw-h-full">
    <div class="tw-flex tw-overflow-hidden tw-min-h-full">
      <div
        v-infinite-scroll="loadMore"
        infinite-scroll-distance="120"
        class="ctk-proposals-list__content tw-flex tw-flex-col tw-flex-1 tw-h-full tw-flex-shrink-0 tw-flex-grow-0 tw-w-full tw-overflow-y-auto"
        data-test="list"
      >
        <div>
          <ProposalItem
            v-for="proposal in getProposals"
            :key="proposal.uuid"
            :proposal="proposal"
            class="flex-fixed"
            @click="showOffer"
          />
        </div>

        <CtkInfiniteLoader
          :loaded-text="$t('all_proposals_are_loaded') | capitalize"
          :load-more-text="$t('load_more_proposals') | capitalize"
          :is-loading="$wait.is('fetching more proposals')"
          :can-load-more="canLoadMore"
          :items-count="getProposalsMeta.item_count"
          @load-more="loadMore"
        />
      </div>

      <div
        class="tw-overflow-y-auto tw-w-full tw-absolute md:tw-relative tw-top-0 tw-left-0"
      >
        <OfferDetail
          v-if="getCurrentProposal"
          :offer="getCurrentOffer || getCurrentProposal.offer"
          :proposal="getCurrentProposal"
          class="tw-mb-8"
          data-test="detail"
        />
        <OfferUnselected
          v-else
          :title="$t('offers.titles.select_proposal')"
          class="tw-hidden md:tw-flex tw-w-full tw-h-full"
          data-test="unselected"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import { Offer } from '@/resources'

  import CtkInfiniteLoader from '@/components/CtkInfiniteLoader/index.vue'
  import OfferDetail from '@/views/Carriers/Offers/components/OfferDetail/index.vue'
  import OfferUnselected from '@/views/Carriers/Offers/components/OfferUnselected/index.vue'
  import ProposalItem from './_subs/ProposalItem/index.vue'

  const allowedProposalStatesForMap = ['pending', 'cancelled', 'refused', 'expired', 'revoked']

  /**
   * @module component proposalsList
   */
  export default defineComponent({
    name: 'ProposalsList',
    components: {
      ProposalItem,
      CtkInfiniteLoader,
      OfferDetail,
      OfferUnselected
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      ...mapGetters('offers', [
        'getCurrentProposal',
        'getCurrentOffer',
        'getProposalsMeta',
        'getProposalsFilter',
        'getProposals'
      ]),
      /**
       * Returns true if we can fetch more proposals
       * @function canLoadMore
       * @returns {boolean}
       */
      canLoadMore () {
        const { pagination } = this.getProposalsMeta
        return pagination.current_page + 1 <= pagination.page_count
      }
    },
    methods: {
      ...mapActions('offers', [
        'retrieveMoreProposals',
        'setCurrentOffer',
        'setCurrentProposal'
      ]),
      /**
       * Retrieve more proposals
       * @function loadMore
       */
      loadMore () {
        if (this.canLoadMore) {
          this.$wait.start('fetching more proposals')

          let state = this.getProposalsFilter
          if (this.$route.name === 'ProposalStates') {
            state = this.$route.params.state
          }

          this.retrieveMoreProposals({
            state
          })
            .finally(() => {
              this.$wait.end('fetching more proposals')
            })
        }
      },
      /**
       * @function showOffer
       * @param {{
       *  uuid: string
       *  offer: {
       *    uuid: string
       *  }
       * }} proposal
       */
      showOffer (proposal) {
        this.setCurrentOffer(proposal.offer)
        this.setCurrentProposal(proposal.uuid)

        /**
         * Retrieve the offer since we do not fully have the data in the proposal
         * payload.
         */
        // @ts-ignore
        if (proposal.offer && proposal.offer.available_for_booking && allowedProposalStatesForMap.includes(proposal.status)) {
          Offer.get({
            cid: this.getCid,
            oid: proposal.offer.uuid
          })
            .then(({ data }) => {
              /**
               * Ensure the current offer is the same as the one that has been
               * requested. To avoid jumps because of the async-ness.
               */
              if (proposal.offer.uuid === data.uuid) {
                this.setCurrentOffer(data)
              }
            })
            .catch(() => {})
        }
      }
    }
  })
</script>

<style lang="scss" scoped>

  .ctk-proposals-list {
    &__content {
      border-right: 1px solid $gray;
      width: 420px;
      flex-basis: 420px;
    }
  }

</style>
